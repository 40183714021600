<template>
  <div v-if="visit">
    <visit-detail-tables v-if="visit.status.key !== 'completed'" />
    <b-custom-tabs v-else id="visitDetailTabs" :tabs="tabs" lazy-once />
  </div>
</template>

<script>
import incidentList from "../../actions/incidents/incidentList.vue";
import ObservationList from "../../actions/observations/list.vue";
import detectionAndSuggestionList from "../../actions/detectionAndSuggestion/list.vue";
import TrainingList from "../../trainings/TrainingList.vue";
import VisitDetailTables from "./VisitDetailTables.vue";

export default {
  components: { VisitDetailTables },
  props: { id: String },
  data() {
    return {
      tabs: [
        { title: "Özet", component: VisitDetailTables },
        { title: "Tespit / Öneri Defteri", component: detectionAndSuggestionList },
        { title: "Gözlemler", component: ObservationList },
        { title: "Olaylar", component: incidentList },
        { title: "Eğitimler", component: TrainingList },
        { title: "Toplantılar" },
        { title: "Ekipmanlar" },
      ],
    };
  },
  computed: {
    visit() {
      return this.$store.getters.visit;
    },
  },
  methods: {
    getVisit() {
      this.$axios
        .get(`/visits/get-visit`, { params: { _id: this.id }, loading: "table" })
        .then((result) => {
          this.$store.commit("setVisit", result.data);
        })
        .catch((err) => {
          /* this.$router.push({ name: "error-404" }); */
          this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
        });
    },
  },
  mounted() {
    this.getVisit();
  },
  destroyed() {
    this.$store.commit("setVisit", null);
  },
};
</script>

<style></style>
