<template>
  <b-row>
    <b-col lg="7">
      <b-card body-class="p-50" class="border mb-50">
        <app-detail-table :fields="fields" :data="visit">
          <template #physician="data">
            <div v-if="data.physician" class="d-flex flex-column">
              <span class="font-weight-bolder">{{ data.physician.auth.fullname }}</span>
              <small>{{ $physicianRole(data.physicianRole) }}</small>
            </div>
            <span v-else> - </span>
          </template>
          <template #status="item">
            <b-badge :variant="$variants[item.status.key]">
              {{ item.status.value }}
            </b-badge>
          </template>
        </app-detail-table>
      </b-card>
    </b-col>
    <b-col v-if="visit.notCompletedReason" lg="5">
      <b-card class="border rounded mb-1">
        <b-card-sub-title class="d-flex gap-10 align-items-center">
          <span class="text-dark">Yapılamama Nedeni: </span>
          <b-badge>{{ visit.notCompletedReason.title }}</b-badge>
        </b-card-sub-title>
        <hr class="m-50" />
        {{ visit.notCompletedReason.description }}
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: "startDate", label: "Planlanan Tarih", formatDate: true },
        { key: "company.name", label: "Firma" },
        { key: "branch.name", label: "Sicil" },
        { key: "status", label: "Durum" },
      ],
    };
  },
  computed: {
    visit() {
      return this.$store.getters.visit;
    },
  },
  methods: {},
};
</script>

<style></style>
